import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import 'prismjs/themes/prism-okaidia.css';
import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';

import Article from '@react-website-themes/classy-docs/components/Article';
import Bodytext from '@react-website-themes/classy-docs/components/Bodytext';
import Heading from '@react-website-themes/classy-docs/components/Heading';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Seo from '@react-website-themes/classy-docs/components/Seo';
import Sidebar from '@react-website-themes/classy-docs/components/Sidebar';
import layoutSidebar from '@react-website-themes/classy-docs/styles/layoutSidebar';

import config from 'content/meta/config';
import categoryList from 'content/meta/categories';
import CustomHeader from '../components/CustomHeader/CustomHeader';

import { trackPageView } from '../utils/telemetry';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const PageTemplate = props => {
  useEffect(() => {
    trackPageView(props.pageContext.slug);
  }, [props.pageContext.slug]);

  const {
    data: {
      page: {
        html: pageHTML,
        frontmatter: { title },
        fields: { slug, source },
        excerpt,
      },
      pages: { edges: nodePages },
      siteSearchIndex: { index },
    },
  } = props;

  const {
    headerTitle,
    headerSubTitle,
    siteUrl,
    siteLanguage,
    siteTitlePostfix,
  } = config;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  const pages = nodePages.map(item => item.node);
  const layoutStyle = source === 'docs' ? layoutSidebar : undefined;

  return (
    <>
      {layoutStyle && (
        <Sidebar
          title="Table of content"
          pages={pages}
          categoryList={categoryList}
          pathname={slug}
        />
      )}
      <Layout themeStyle={layoutStyle}>
        <CustomHeader
          isMobile={isTabletOrMobile}
          headerTitle={headerTitle}
          headerSubTitle={headerSubTitle}
          searchIndex={index}
        />
        <Article>
          <Heading title={title} />
          <Bodytext html={pageHTML} />
        </Article>
        <Seo
          url={`${siteUrl}${slug}`}
          language={siteLanguage}
          title={`${title}${siteTitlePostfix}`}
          description={excerpt}
        />
        <ToastContainer
          closeButton={false}
          draggable={false}
          position="top-right"
          hideProgressBar
        />
      </Layout>
    </>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default PageTemplate;

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fileAbsolutePath
      fields {
        slug
        prefix
        source
      }
      frontmatter {
        title
        categories
      }
    }
    pages: allMarkdownRemark(
      filter: { fields: { source: { eq: "docs" } } }
      sort: { fields: [fields___prefix] }
    ) {
      edges {
        node {
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            shortTitle
            categories
          }
          headings {
            value
            depth
          }
          tableOfContents
        }
      }
    }
    footerLinks: markdownRemark(
      fileAbsolutePath: { regex: "/content/parts/footerLinks/" }
    ) {
      html
    }
    copyright: markdownRemark(
      fileAbsolutePath: { regex: "/content/parts/copyright/" }
    ) {
      html
    }
    siteSearchIndex {
      index
    }
  }
`;
